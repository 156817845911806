

















import { Vue, Component, Ref, PropSync, Prop } from 'vue-property-decorator'
import request from '@/common/request'
import axios from 'axios'
import { Fragment } from 'vue-fragment'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		Fragment,
	},
})
export default class WrapperDeUpload extends Vue {
	@PropSync('value') url!: string
	@Prop({ type: String }) bucketName?: string
	@Prop({
		type: Function,
		default: undefined,
	}) onError?: (error: Error) => void
	@Ref() fileInput!: {
		$el: HTMLInputElement
	}
	@Prop({ type: String, default: 'default' }) folderName?: string
	carregando = false

	abrir() {
		const fileInput = this.fileInput.$el
		const button = fileInput.querySelector('button')
		if (button) button.click()
	}

	async upload(file: File) {
		if (!file) return

		const isValid = this.validarNomeDoArquivo(file);
		if (!isValid) {
			return;
		}

		try {
			this.carregando = true
			const presignedUrl = await request.post<PreSignedBody, string>('/pre-signed-url', {
				fileName: file.name,
				folderName: this.folderName,
				contentType: file.type,
				fileSize: file.size,
				bucketName: this.bucketName,
				httpMethod: 'PUT',
			})

			await axios.put(presignedUrl, file, {
				headers: {
					'Content-Type': file.type,
				},
			})

			const url = new URL(presignedUrl)
			url.search = ''

			this.$emit('input', url.toString())
		} catch (error: any) {
			if (this.onError) this.onError(error)
		} finally {
			this.carregando = false
		}
		
	}

	validarNomeDoArquivo(file) {
		const nomeOriginal = file.name;
		const extensaoIndex = nomeOriginal.lastIndexOf('.pfx');

		if (extensaoIndex === -1 || nomeOriginal.substring(extensaoIndex) !== '.pfx') {
			AlertModule.setError("O arquivo deve ter a extensão .pfx.");
			this.resetFileInput();
			return false;
		}

		let nomeBase = nomeOriginal.substring(0, extensaoIndex);

		nomeBase = nomeBase.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		nomeBase = nomeBase.replace(/[^a-zA-Z0-9]/g, "");

		const nomeValidado = nomeBase + '.pfx';

		if (nomeValidado !== nomeOriginal) {
			AlertModule.setError("O nome do arquivo deve conter apenas letras e números, sem espaços ou acentuações. Tente novamente após realizar a correção.");
			return false;
		}
		return true;
	}

	resetFileInput() {
		if (this.fileInput && this.fileInput.$el) {
			this.fileInput.$el.value = '';
		}
	}


}

interface PreSignedBody {
	fileName: string
	contentType: string
}
