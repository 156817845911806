





















































































































































































































































































import { Vue, Component, Prop, Watch, Ref, PropSync } from 'vue-property-decorator'
import { FormPontoDeVenda } from '@/models'
import type { FechamentoDeCaixa, Loja, TurnoDeVenda } from '@/models'
import type { PontoDeVenda } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import SeletorDeSerieFiscal from './SeletorDeSerieFiscal.vue'
import StatusDoPontoDeVenda from './StatusDoPontoDeVenda.vue'
import { SerieFiscal } from '@/models/SerieFiscal'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { verificarSerieFiscalSemPontoDeVendaAssociado } from '@/shareds/loja-shareds'
import { EditarPdvUseCase, FecharCaixaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '@/components/ui/Confirmacao.vue'

@Component({
	components: {
		SeletorDeSerieFiscal,
		StatusDoPontoDeVenda,
		Confirmacao,
	},
})
export default class DialogoDePontoDeVenda extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNome!: HTMLInputElement
	@Ref() confirmacaoDeDescartarVenda!: Confirmacao
	@Ref() confirmacaoDeEncerrarTurno!: Confirmacao
	@Prop({ type: Object, default: criarPontoDeVenda }) initialValue!: PontoDeVenda
	@Prop({ type: Boolean, default: false }) disabled!: boolean
	@Prop({ type: Boolean, default: false }) readonly!: boolean
	@PropSync('loja', { type: Object }) lojaSync?: Loja
	
	mostra = false
	pontoDeVenda: FormPontoDeVenda = criarPontoDeVenda()
	obrigatorio = obrigatorio
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	editarPdvUseCase = new EditarPdvUseCase()
	fecharCaixaUseCase = new FecharCaixaUseCase()
	turnoDeVenda: TurnoDeVenda[] = []

	get verificarSerieFiscalSemPontoDeVendaAssociado() {
		return verificarSerieFiscalSemPontoDeVendaAssociado(this.pdvsSemAtual)
	}

	get pdvsSemAtual() {
		return this.lojaSync?.pdvs.filter(
			(pdv) => pdv.id !== this.pontoDeVenda.id,
		) || []
	}

	get integracaoControlPayAtiva() {
		return this.lojaSync ? this.lojaSync.configuracaoDaLoja.integracaoControlPayAtiva : false
	}

	async abrirFecharCaixa() {
		try {
			this.pontoDeVenda.aberto ? this.confirmacaoDeEncerrarTurno.mostrar() : this.pontoDeVenda = await this.editarPdvUseCase.editarSituacaoPdv(this.pontoDeVenda.id, !this.pontoDeVenda.aberto, false)
			this.initialValue.aberto = this.pontoDeVenda.aberto
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.pontoDeVenda = JSON.parse(JSON.stringify(this.initialValue))
		setTimeout(() => {
			this.form.resetValidation()
			setTimeout(() => {
				this.campoDeNome.focus()
			})
		})
	}

	@Watch('pontoDeVenda.exigeVendedor')
	onChangeExigeVendedor() {
		if (!this.pontoDeVenda.exigeVendedor) {
			this.pontoDeVenda.obrigaInformarVendedor = false
			this.pontoDeVenda.autoPreencherVendedor = false
		}
	}

	confirmar() {
		if (!this.form.validate()) return
		this.$emit('confirma', this.pontoDeVenda)
		this.mostra = false
	}

	inserirSerieFiscal(serieFiscal: SerieFiscal) {
		if (this.lojaSync) {
			this.lojaSync = {
				...this.lojaSync,
				seriesFiscais: [
					...this.lojaSync.seriesFiscais,
					serieFiscal,
				],
			}
		}
		this.pontoDeVenda = {
			...this.pontoDeVenda,
			serieFiscal,
		}
	}

	@Watch("pontoDeVenda.podeGerarOrcamento")
	atualizarConfigDePedido() {
		if (!this.pontoDeVenda.podeGerarOrcamento) {
			this.pontoDeVenda.criaPedidoNaVenda = false
		}
	}

	@Watch("pontoDeVenda.criaPedidoNaVenda")
	atualizarConfigDaCriaPedidoNaVenda() {
		if (this.pontoDeVenda.criaPedidoNaVenda) {
			this.pontoDeVenda.vendaForaDoEstabelecimento = false
		}
	}

	@Watch("pontoDeVenda.vendaForaDoEstabelecimento")
	atualizarConfigDaVendaForaDoEstabelecimento() {
		if (this.pontoDeVenda.vendaForaDoEstabelecimento) {
			this.pontoDeVenda.criaPedidoNaVenda = false
		}
	}

	@Watch("pontoDeVenda.podeGerarOrcamento")
	onChangePodeGerarOrcamento() {
		if (this.pontoDeVenda.podeGerarOrcamento) {
			this.pontoDeVenda.criaPedidoNaVenda = false
		}
	}

	async encerrarTurnoDeVenda(encerrarTurnoDeVenda: boolean, fechamento?: FechamentoDeCaixa){
		try {
			if(encerrarTurnoDeVenda){
				const turnoDeVenda = await this.fecharCaixaUseCase.findTurnoDeVenda(this.pontoDeVenda.id)
				if(!turnoDeVenda.id) return

				fechamento = {...turnoDeVenda.fechamento,
					dataHoraFechamento: new Date(),
					turnoDeVenda: turnoDeVenda,
					anexos: [],
				}

				await this.fecharCaixaUseCase.execute(turnoDeVenda.id, fechamento)
			}

			this.initialValue.aberto = this.pontoDeVenda.aberto
			this.pontoDeVenda = await this.editarPdvUseCase.editarSituacaoPdv(this.pontoDeVenda.id, !this.pontoDeVenda.aberto, encerrarTurnoDeVenda)

		} catch (e) {
			AlertModule.setError(e)
		}
	}
}

function criarPontoDeVenda(): FormPontoDeVenda {
	return {
		id: '',
		identificador: '',
		nome: '',
		valorEmCaixa: 0,
		aberto: false,
		ativo: true,
		exigeCliente: false,
		obrigaInformarVendedor: false,
		exigeVendedor: false,
		autoPreencherVendedor: false,
		parametrosFiscais: null,
		isHomologacao: false,
		serieFiscal: null,
		criaPedidoNaVenda: false,
		imprimeReciboNoOrcamento: true,
		agrupaProdutosNaVenda: false,
		imprimeComprovanteDoFechamento: true,
		idTerminalControlPay: '',
		createdAt: '',
		permiteSelecaoDeProduto: false,
		habilitarLeitor: false,
		enviarNotaPorEmail: false,
		buscarProdutoNoCaixa: false,
		podeGerarNFCE: false,
		podeGerarNFE: false,
		podeGerarOrcamento: false,
		vendaForaDoEstabelecimento: false,
		exigeModalidade: false,
		exigeClienteCadastrado: false,
		naoMovimentaEstoque: false,
		autoEnviarWhatsapp: false,
	}
}

