























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import BuscaDeGrupoEconomico from './BuscaDeGrupoEconomico.vue'
import { v4 as uuidv4 } from 'uuid'
import SeletorDeUsuario from '../usuario/SeletorDeUsuario.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import type { ConfiguracaoDeNotificacaoPorUsuario } from '@/models'

@Component({
	components: {
		BuscaDeGrupoEconomico,
		SeletorDeUsuario,
	},
})
export default class DialogoDeConfiguracaoDeNotificacaoPorLoja extends Vue {
	@Prop() modulo!: 'Estoque' | 'Vendas' | 'Nota em Transito'
	@Prop() configuracaoDeNotificacaoPorLoja!: ConfiguracaoDeNotificacaoPorUsuario
	@Prop() lojaId!: string
	mostra = false
	salvando = false
	configuracaoEmEdicao: ConfiguracaoDeNotificacaoPorUsuario | null = null
	
	get configuracoesDoModulo() {
		if (!this.modulo) return []

		switch(this.modulo) {
			case 'Estoque': 
				return ['Estoque negativo']
			case 'Vendas': 
				return ['Venda com status inválido']
			case 'Nota em Transito':
				return ['Nota em trânsito inconsistente']
			default:
				throw Error('Módulo não esperado')
		}
	}

	get editarAdicionar() {
		if (!this.configuracaoDeNotificacaoPorLoja) return 'Adicionar'
		if (!this.configuracaoDeNotificacaoPorLoja.id) return 'Adicionar'
		
		return 'Editar'
	}

	@Watch('mostra')
	onChangeMostra() {
		if (!this.modulo) return
		if (!this.mostra) return
		if (!this.configuracaoDeNotificacaoPorLoja) return

		if (!this.configuracaoDeNotificacaoPorLoja.id) {
			this.configuracaoEmEdicao = {
				id: uuidv4(),
				lojaId: this.lojaId,
				usuario: null,
				modulo: this.modulo,
				tipoNotificacao: null,
			} as ConfiguracaoDeNotificacaoPorUsuario
		} else {
			this.configuracaoEmEdicao = {
				id: this.configuracaoDeNotificacaoPorLoja.id,
				lojaId: this.configuracaoDeNotificacaoPorLoja.lojaId,
				usuario: this.configuracaoDeNotificacaoPorLoja.usuario,
				modulo: this.configuracaoDeNotificacaoPorLoja.modulo,
				tipoNotificacao: this.configuracaoDeNotificacaoPorLoja.tipoNotificacao,
			} as ConfiguracaoDeNotificacaoPorUsuario
		}
	}

	salvar() {
		try {
			this.$emit('editar', this.configuracaoEmEdicao)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.mostra = false
		}
	}
}

